import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/ui/LoadingError.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/ui/pdf-viewer/AllPagesPDFViewer.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/ui/SanityImage.tsx");
