import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type UseFormProps } from "react-hook-form";
import type { ParseParams, ZodType } from "zod";

import { SM_SCREEN_WIDTH } from "@/src/lib/constants";

type Props<TSchema extends ZodType> = Omit<
  UseFormProps<TSchema["_input"]>,
  "resolver"
> & {
  schema: TSchema;
  schemaOptions?: Partial<ParseParams>;
};

export function useZodForm<TSchema extends ZodType>({
  schema,
  schemaOptions,
  ...props
}: Props<TSchema>) {
  return useForm<TSchema["_input"]>({
    ...props,
    resolver: zodResolver(schema, schemaOptions),
  });
}

export const useIsOnDesktop = () => {
  const [isOnDesktop, setIsOnDesktop] = useState<boolean>();

  useEffect(() => {
    setIsOnDesktop(window.innerWidth >= SM_SCREEN_WIDTH);
  }, []);

  return isOnDesktop;
};
