import { cn } from "@/src/lib/utils";

export default function Loader({
  variant,
  className,
}: {
  variant?: "spinner" | "dots";
  className?: string;
}) {
  return (
    <div className={cn("flex w-full justify-center", className)}>
      <div
        className={cn(
          "loading loading-md",
          variant ? `loading-${variant}` : "loading-spinner",
        )}
      />
    </div>
  );
}
