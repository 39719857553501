import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatArticleDate({
  date,
  locale,
}: {
  date: Date;
  locale: string;
}) {
  return `📆 ${date.toLocaleDateString(locale, {
    day: "numeric",
    month: "long",
    year: "numeric",
  })}`;
}
