import daisyuiThemes from "daisyui/src/theming/themes";
import icons from "rocketicons/tailwind";
import type { Config } from "tailwindcss";

const config: Config = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    "node_modules/daisyui/dist/**/*.js",
  ],
  theme: {
    extend: {
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic":
          "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
      },
      transitionProperty: {
        "height-opacity": "height, opacity",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("tailwind-scrollbar"),
    require("daisyui"),
    require("tailwindcss-animate"),
    icons,
  ],
  daisyui: {
    themes: [
      {
        light: {
          ...daisyuiThemes["emerald"],
          ".img-shadow": {
            "--tw-shadow-color": "#9ca3af",
            "--tw-shadow": "var(--tw-shadow-colored)",
            "border-color": "#9ca3af",
          },
        },
      },
      {
        dark: {
          ...daisyuiThemes["night"],
          ".img-shadow": {
            "--tw-shadow-color": "#030712",
            "--tw-shadow": "var(--tw-shadow-colored)",
            "border-color": "#030712",
          },
        },
      },
    ],
  },
};
export default config;
