"use client";

import Image, { ImageProps } from "next/image";
import { SanityImageSource } from "@sanity/asset-utils";
import { useNextSanityImage } from "next-sanity-image";

import { client } from "@/sanity/lib/client";
import { cn } from "@/src/lib/utils";

type SanityImageProps = {
  sanityImageSource: SanityImageSource;
  className?: string;
} & Omit<ImageProps, "src">;

export default function SanityImage({
  sanityImageSource,
  className,
  ...props
}: SanityImageProps) {
  const sanityImageProps = useNextSanityImage(client, sanityImageSource, {
    imageBuilder: (imageUrlBuilder, options) => {
      return imageUrlBuilder
        .width(
          options.width ||
            Math.min(options.originalImageDimensions.width, 1500),
        )
        .quality(options.quality || 75)
        .fit("clip")
        .format("webp");
    },
  });

  return (
    <Image
      src={sanityImageProps.src}
      width={sanityImageProps.width}
      height={sanityImageProps.height}
      sizes="(max-width: 800px) 100vw, 800px"
      className={cn("img-shadow rounded-md border-[1px] shadow-md", className)}
      {...props}
      alt={props.alt}
    />
  );
}
