"use client";

import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "./pdfViewerStyles.css";

import Loader from "@/src/components/ui/Loader";
import { useIsOnDesktop } from "@/src/lib/hooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type AllPagesProps = {
  pdfUrl: string;
};
export default function AllPagesPDFViewer({ pdfUrl }: AllPagesProps) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const isOnDesktop = useIsOnDesktop();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return isOnDesktop === undefined ? (
    <Loader />
  ) : (
    <Document
      file={pdfUrl}
      loading={<Loader />}
      onLoadSuccess={onDocumentLoadSuccess}
      className="mx-auto w-fit"
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          scale={isOnDesktop ? 1 : 0.5}
          className="img-shadow mb-5 shadow-md [&_.react-pdf\_\_Page\_\_textContent]:!img-shadow"
        />
      ))}
    </Document>
  );
}
