"use client";

import React, { useTransition } from "react";
import { useRouter } from "next/navigation";
import { useTranslations } from "next-intl";

import H2 from "@/src/components/ui/H2";
import Loader from "@/src/components/ui/Loader";

export default function LoadingError() {
  const t = useTranslations("Error");
  const router = useRouter();
  const [isPending, startTransition] = useTransition();

  function retry() {
    startTransition(() => router.refresh());
  }

  return (
    <div className="flex flex-col items-center gap-3 text-error">
      <H2>{t("loadingError")}</H2>
      <button onClick={retry} disabled={isPending} className="btn btn-sm w-fit">
        {isPending ? <Loader /> : t("retry")}
      </button>
    </div>
  );
}
