import { cn } from "@/src/lib/utils";

type SubTitleProps = {
  className?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLHeadingElement>;

export default function H2({ className, children, ...props }: SubTitleProps) {
  return (
    <h2
      {...props}
      className={cn(
        "text-center text-2xl tracking-tighter sm:text-3xl",
        className,
      )}
    >
      {children}
    </h2>
  );
}
