import resolveConfig from "tailwindcss/resolveConfig";

import { DaisyuiColor, Role } from "@/src/lib/types";

import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

export const SM_SCREEN_WIDTH = Number(
  fullConfig.theme.screens.sm.replace("px", ""),
);

export const roleName = {
  admin: "admin",
  committee: "comité",
  delegate: "délégué",
  coowner: "copropriétaire",
  all: "tous",
};

export const roleColor: Record<Role, DaisyuiColor> = {
  admin: "error",
  committee: "secondary",
  delegate: "accent",
  coowner: "primary",
  all: "ghost",
};

// Key = role, value = list of what protected pages this role can access
export const roleVisibility: Record<Role, Role[]> = {
  admin: ["coowner", "delegate", "committee", "admin"], // admins can access everything
  committee: ["coowner", "delegate", "committee"], // committee members can access everything except admin pages
  delegate: ["coowner", "delegate"], // delegates can access delegate and coowner pages
  coowner: ["coowner"], // coowners can only access coowner pages
  all: [], // non-logged in user can't access any protected page
};
